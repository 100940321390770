import { cognitoidentityserviceprovider } from "./dynamoDb";
import swal from "sweetalert";
import firebase from 'firebase'
import * as firebaseRefs from '../firebase/firesbaseRefs'

export const logInFirebase = async (username, password) => {
    try {
        await firebase.auth().signInWithEmailAndPassword(username, password)
        window.location.href = "/";
    }
    catch (err) {
        swal("เกิดข้อผิดพลาด", err.message, "error");
    }

}

export const checkAuth = async (cbSuccess, cbError) => {
    firebase.auth().onAuthStateChanged(async (user, err) => {
        if (user === null || user === undefined) {
            // swal("เกิดข้อผิดพลาด", err, "error");
            cbError && cbError()
            return
        }
        let uid = user.uid
        const userDetail = await firebaseRefs.fireStoreUserRef.doc(uid).get()
        const userFromFireStore = await userDetail.data()
        cbSuccess && cbSuccess(userFromFireStore)
    })
}