import React from 'react'
import _ from 'lodash'
import moment from 'moment'

const MenuListModal = ({ job }) => {

    let items = []

    try {
        items = job.reducer.cart.items
    }
    catch (err) {
        items = []
    }

    return (
        <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        {
                            !_.isEmpty(job) && <h5 class="modal-title" id="exampleModalLabel"><span style={{ color: 'blue' }}>{job.to.contact.name}</span> {moment(job.to.timestamp).format("HH:mm")} <span style={{ color: 'grey' }}>{moment(job.to.timestamp).format(" DD/MM/YYYY")} </span></h5>
                        }
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <table class="table table-sm">
                            <thead class="thead-dark">
                                <tr>
                                    <th scope="col">Qty</th>
                                    <th scope="col">Name</th>
                                    <th scope="col">Price</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    _.map(items, (item, index) => {
                                        return (
                                            <tr key={`m${index + 1}`}>
                                                <td>{item.qty}</td>
                                                <td>{item.name}</td>
                                                <td>{item.discountedPrice.toLocaleString()}</td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MenuListModal