import React from 'react';
import _ from 'lodash'
import { fetchBranchesApi } from '../../shared/api/fetch'
import async from 'async'
// import { signUp } from '../../shared/api/authen'
import randomString from "random-string";
import documentClient from '../../shared/api/dynamoDb'

class CreateUserForm extends React.Component {
    state = {
        username: '',
        password: '',
        shopName: '',
        branches: [],
        txtBranch: ''
    }

    render() {

        const { branches, txtBranch, shopName, username, password } = this.state

        return (
            <div>
                <div className="row" style={{ margin: 0 }}>
                    <DivColMd3 className="col-md-3" style={{ margin: 0, padding: 0 }} >
                        <small style={{ color: 'grey' }}>ชื่อสาขา HQ</small>
                        <input className="form-control" type="text" placeholder="Ex.:Shabuku HQ"
                            value={shopName} name="shopName" onChange={this.handleOnChange} />
                    </DivColMd3>
                </div>
                <div className="row" style={{ margin: 0 }}>
                    <DivColMd3>
                        <small style={{ color: 'grey' }}>Username</small>
                        <input className="form-control" type="text"
                            value={username} name="username" onChange={this.handleOnChange}
                        />
                    </DivColMd3>
                    <div className="col-md-3" >
                        <small style={{ color: 'grey' }}>Password</small>
                        <input className="form-control" type="text"
                            placeholder="อย่างน้อย 6 ตัวอักษร"
                            value={password} name="password" onChange={this.handleOnChange}
                        />
                    </div>
                </div>
                <div className="row" style={{ margin: 0, padding: 0 }}>
                    <DivColMd3>
                        <small style={{ color: 'grey' }}>เพิ่มสาขา</small>
                        <input className="form-control" type="text" placeholder="Ex.:3180" value={txtBranch} name="txtBranch" onChange={this.handleOnChange} />
                    </DivColMd3>
                    <div className="col-md-3 align-self-end">
                        <button onClick={this.addBranch} type="button" className="btn btn-success waves-effect waves-light" >Add</button>
                    </div>
                </div>
                <div className="row" style={{ margin: 0, marginTop: 15 }}>
                    {
                        _.map(branches, (branch, index) => {
                            return (
                                <div className="col-md-2">
                                    <div className=" card" style={{ backgroundColor: 'lightgrey' }}>
                                        <div style={{ margin: 10 }}>
                                            <div style={{ margin: 0 }}>
                                                <div className="row justify-content-between" style={{ margin: 0 }}>
                                                    <div>{branch.customerId}</div>
                                                    <div><a href="#"
                                                        onClick={e => {
                                                            e.preventDefault()
                                                            this.deleteBranch(index)
                                                        }}
                                                        style={{ color: 'red' }}><i className="dripicons-trash"></i></a></div>
                                                </div>
                                                <div> {branch.name}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }


                </div>
                <button
                    disabled={this.validate()}
                    onClick={this.createUser} type="button" className="btn btn-info waves-effect waves-light">CREATE</button>
            </div>
        );
    }

    validate = () => {
        const { username, password, shopName, branches } = this.state
        if (username === '' || password === '' || shopName === '' || branches.length < 1) {
            return true
        }
        return false
    }

    createUser = () => {
        const { username, password, shopName, branches } = this.state
        if (password.length < 6) {
            alert('กรุณากรอก Password อย่างน้อย 6 ตัวอักษร')
            return
        }

        let randomCustomerId = randomString({
            length: 8,
            numeric: true,
            letters: false,
            special: false
        });

        async.waterfall([
            (cb) => {
                // signUp({ username, password }, cb)
            },
            (cb) => {
                let branchesArray = []
                _.map(branches, i => {
                    branchesArray.push(i.customerId)
                })
                let params = {
                    TableName: "V1_HQ_HQ",
                    Item: {
                        uid: randomCustomerId,
                        branches: branchesArray
                    }
                };
                documentClient.put(params, function (err, data) {
                    if (err) {
                        console.log(err);
                        cb(err);
                    } else {
                        cb();
                    }
                });
            },
            (cb) => {
                let params = {
                    TableName: "V1_HQ_Users",
                    Item: {
                        username: username,
                        customerId: randomCustomerId,
                        role:'admin'
                    }
                };
                documentClient.put(params, function (err, data) {
                    if (err) {
                        console.log(err);
                        cb(err);
                    } else {
                        cb();
                    }
                });
            }

        ], (err, result) => {
            if (err) {
                alert(err)
                return
            }
            alert('Create User Complete')
            window.location.href = '/admin'
        })


    }

    addBranch = (e) => {
        const { txtBranch } = this.state
        if (txtBranch === '') return
        let self = this
        fetchBranchesApi([txtBranch])
            .then(res => {
                if (res === undefined || res.data.length < 1) {
                    alert('ไม่มีร้านหมายเลขนี้')
                    return
                }
                let branches = _.cloneDeep(this.state.branches)
                branches.push({ customerId: txtBranch, name: res.data[0].Value })
                self.setState({
                    branches,
                    txtBranch: ''
                })
            })
    }

    deleteBranch = (index) => {
        const { branches } = this.state
        let tmpBranch = [...branches.slice(0, index), ...branches.slice(index + 1)]
        this.setState({
            branches: tmpBranch
        })
    }


    handleOnChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

}

const DivColMd3 = ({ children }) => {
    return (
        <div className="col-md-3" style={{ padding: 0 }}>
            {children}
        </div>
    )

}

export default CreateUserForm;