import async from 'async'
import { fetchLoggedInUser } from '../actions/userActions'
import { fetchShop } from '../actions/shopActions'
import { fetchBranches } from '../actions/branchesActions'
import { fetchDapartments } from '../actions/menuActions'
import { fetchTopping } from '../actions/toppingActions'

import { checkAuth } from '../../api/authen';

const InitMiddleware = store => next => {
    return action => {
        if (action.type === "INIT") {
            checkAuth(
                (user) => {
                    //fetch
                    async.waterfall([
                        (cb) => {
                            store.dispatch(fetchLoggedInUser(user, cb))
                        },
                        (cb) => {
                            store.dispatch(fetchShop(user, cb))
                        },
                        (cb) => {
                            store.dispatch(fetchBranches(user, cb))
                        },
                        (cb) => {
                            store.dispatch(fetchTopping(user))
                            store.dispatch(fetchDapartments(user, cb))
                        }
                    ])
                }
            )

        }
        next(action)
    }


}

export default InitMiddleware