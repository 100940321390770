import React from 'react'
import _ from 'lodash'
import MediaQuery from 'react-responsive';

class CatalogCard extends React.Component {

    render() {
        const { menu, isTopping } = this.props

        return (
            <div>
                <MediaQuery maxDeviceWidth={500}>
                    <div className="card">
                        <div >
                            <div id="accordion">
                                {
                                    _.map(menu, (group) => {
                                        return (
                                            <div className="card mb-0">
                                                <div className="card-header p-3" id="headingOne" style={{ borderBottom: '1px solid', borderBottomColor: 'lightgrey' }}>
                                                    <h6 className="m-0">
                                                        <a href={`#G${group.uuid}`} data-toggle="collapse" aria-expanded="false" aria-controls={`G${group.uuid}`}>
                                                            <div className="row m-0 justify-content-between">
                                                                <div>
                                                                    <span >{group.name}</span>
                                                                </div>
                                                                <div>
                                                                    <span><i class="mdi mdi-arrow-expand"></i></span>
                                                                </div>
                                                            </div>
                                                        </a>
                                                    </h6>
                                                </div>

                                                <div id={`G${group.uuid}`} className="collapse" aria-labelledby="headingOne" data-parent="#accordion" >
                                                    <div style={{ margin: 7 }}>
                                                        <ul class="list-group">
                                                            {
                                                                _.map(group.items, (item) => {
                                                                    return (
                                                                        <li class="list-group-item" style={{ paddingLeft: 0 }}>
                                                                            <div className="row m-0 justify-content-between">
                                                                                <div>
                                                                                    <span style={{ color: 'blue', paddingLeft: 9, fontWeight: 'bold', marginRight: 4 }}>{item.qty.toLocaleString()}</span> {item.name}
                                                                                </div>
                                                                                <div>
                                                                                    <b>{(item.discountedPrice || 0).toLocaleString()}</b>
                                                                                </div>
                                                                            </div>
                                                                        </li>
                                                                    )
                                                                })
                                                            }
                                                        </ul>

                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </MediaQuery>
                <MediaQuery minDeviceWidth={501}>
                    <div>
                        <div className="card">
                            <div className="card-body">
                                <div className=" table-bordered" style={{ marginBottom: 20 }}>
                                    <table className="table mb-0 product-table">
                                        <thead className="thead-default">
                                            <tr>
                                                <th
                                                    // colSpan={2}
                                                    style={{ width: '50%' }}>Name</th>
                                                <th>Quantity</th>
                                                {!isTopping &&
                                                    <th>Amount</th>
                                                }
                                                {/* <th>Percent<br />{catalog.Percent.toLocaleString()}%</th> */}
                                            </tr>
                                        </thead>
                                        {
                                            _.map(menu, (group) => {
                                                return (
                                                    [<tr>
                                                        <td colSpan={isTopping ? 2 : 3} style={{ width: '5%', color: 'blue', backgroundColor: 'lightgrey' }}> {group.name}</td>
                                                    </tr>,
                                                    _.map(group.items, (item) => {
                                                        return (
                                                            <tr >
                                                                <td><span >{item.name}</span></td>
                                                                <td ><span style={{ fontWeight: 'bold' }}>{item.qty.toLocaleString()}</span></td>
                                                                {!isTopping &&
                                                                    <td ><span style={{ fontWeight: 'bold' }}>{(item.discountedPrice || 0).toLocaleString()}</span></td>
                                                                }
                                                            </tr>
                                                        )
                                                    })
                                                    ]
                                                )
                                            })

                                        }
                                    </table>
                                </div>

                            </div>
                        </div>
                        {/* </MediaQuery> */}
                    </div >
                </MediaQuery>
            </div>
        )

    }
}



export default CatalogCard;

const ExpanTitleButton = ({ id, title, disabled }) => {
    return (
        <button disabled={disabled} className="btn btn-link" data-toggle="collapse" data-target={`#${id}`} aria-expanded="true" aria-controls={`#${id}`}>
            {title}
        </button>

    )
}

