let firebase = require("firebase");
require("firebase/firestore");

let config = {
    apiKey: "AIzaSyCI-lOGcHMqg-9pklgh5JIJpY5wKM9E_Ds",
    authDomain: "tracking-7fb38.firebaseapp.com",
    databaseURL: "https://tracking-7fb38.firebaseio.com",
    projectId: "tracking-7fb38",
    storageBucket: "tracking-7fb38.appspot.com",
    messagingSenderId: "971814685559"
};


firebase.initializeApp(config);
firebase.firestore().enablePersistence();

//REALTIME DATABASE
export const rootRef = firebase.database().ref();
export const branchRef = rootRef.child('branch')
export const jobsDeliveryRef = rootRef.child('jobs_delivery')
export const departmentsRef = rootRef.child('departments')
export const departmentsToppingRef = rootRef.child('topping')

//FIRESTORE
export let fireStoreRootRef = firebase.firestore()
export let fireStoreUserRef = fireStoreRootRef.collection("users")
export let firestoreShopsRef = fireStoreRootRef.collection("shops")
export let firestoreLivescoreDeliveryRef = fireStoreRootRef.collection("live_score_delivery")
export let receiptsDeliveryRef = fireStoreRootRef.collection("receipts_delivery")




