
import * as type from './type'

export const fetchLoggedInUser = (user, cb) => {
    return (dispatch, getState) => {
        dispatch({
            type: type.FETCH_LOGGEDIN_USER,
            payload: user || {}
        });

        cb && cb()
    };
};

// const fetchGet = (params, cb) => {
//     documentClient.get(params, (err, data) => {
//         if (err) {
//             console.log(err);
//             // alert(err)
//         } else {
//             cb(data);
//         }
//     });
// };