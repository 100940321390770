import React from 'react'
import MediaQuery from 'react-responsive';
import moment from 'moment'
import _ from 'lodash'

export const JobCardMobile = ({ job, selectJob }) => {
    return (
        <div>
            <MediaQuery maxDeviceWidth={500}>
                <div class="card">
                    <h6 class="card-header mt-0" style={{ backgroundColor: 'lightgrey' }}>
                        <div className="row m-0 justify-content-between">
                            <div>
                                {job.to.contact.name} ({job.to.contact.phone})
                            </div>
                            <div >
                                {moment(job.to.timestamp).format("HH:mm")}
                            </div>
                        </div>
                    </h6>
                    <div class="card-body">
                        <div>
                            <div className="row m-0">
                                <div className="col-1 pl-0">
                                    <i class="mdi mdi-home" style={{ color: 'grey', fontSize: 20 }}></i>
                                </div>
                                <div className="col-11" style={{ marginTop: 4 }}>
                                    <span>{job.to.address}</span>
                                </div>
                            </div>
                        </div>
                        <div className="mt-1">
                            <div className="row m-0">
                                <div className="col-1 pl-0">
                                    <i class="mdi mdi-note-text" style={{ color: 'grey', fontSize: 20 }}></i>
                                </div>
                                <div className="col-11" style={{ marginTop: 4 }}>
                                    <span  >{job.to.note}</span>
                                </div>
                            </div>
                        </div>
                        <div className="mt-1">
                            <div className="row m-0">
                                <div className="col-1 pl-0">
                                    <i class="mdi mdi-currency-usd" style={{ color: 'grey', fontSize: 20 }}></i>
                                </div>
                                <div className="col-11" style={{ marginTop: 4 }}>
                                    <span  >{(job.grandTotal).toLocaleString()}</span>
                                    <span className="pl-2">({job.reducer.review.payment && job.reducer.review.payment.name})</span>
                                </div>
                            </div>
                        </div>
                        <div className="mt-1">
                            <a
                                href="#"
                                onClick={e => {
                                    e.preventDefault()
                                    e.stopPropagation()
                                    selectJob()
                                }} data-toggle="modal" data-target="#exampleModal">
                                View Items
                                    </a>
                        </div>
                    </div>
                </div>
            </MediaQuery>
        </div>
    )

}

export const JobCardDesktop = ({ job, selectJob }) => {


    return (
        <div>
            <MediaQuery minDeviceWidth={501}>
                <div className="row m-0" >
                    <div className="col-md-10 offset-md-1">
                        <div className="card">
                            <div className="card-content">
                                <div style={{ margin: 15 }}>
                                    <div className="row m-0 justify-content-between">
                                        <div className="col-md-8 pl-0">
                                            <FirstLeft size="desktop" job={job} />
                                        </div>
                                        <div >
                                            <div>
                                                <span className="pr-2" >
                                                    {job.reducer.review.payment && job.reducer.review.payment.name}
                                                </span>
                                                <span className="pl-2" style={{ fontSize: 20 }}>
                                                    <b>{(job.grandTotal).toLocaleString()}</b>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row m-0 mt-2 justify-content-between">
                                        <div className="col-md-4 pl-0">
                                            <span className="pr-2" >
                                                {job.to.contact.name}
                                            </span>
                                            <span className="pl-2" >
                                                <b>{job.to.contact.phone}</b>
                                            </span>
                                        </div>
                                        <div className="mt-2">
                                            <span style={{ color: 'grey' }}>{job.to.address}</span>
                                        </div>
                                    </div>
                                    <div className="row m-0 mt-2 justify-content-between">
                                        <div >
                                            <span style={{ color: 'red' }}>{job.sender && job.sender.contact.name}</span>
                                        </div>
                                        <div >
                                            <span style={{ color: 'red' }}>{job.edit && job.edit.reason}</span>
                                        </div>
                                        <div >
                                            <span style={{ color: 'red' }}>{job.void && job.void.reason}</span>
                                        </div>
                                        <div >
                                            <span style={{ color: 'grey' }}>{job.to.note}</span>
                                        </div>
                                    </div>
                                    <StatusList size="desktop" job={job} />
                                    <div className="mt-3">
                                        <a
                                            href="#"
                                            onClick={e => {
                                                e.preventDefault()
                                                e.stopPropagation()
                                                selectJob()
                                            }} data-toggle="modal" data-target="#exampleModal">
                                            View Items
                                    </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </MediaQuery>
        </div>
    )
}

const FirstLeft = (props) => {
    const job = props.job
    return (
        (
            <div className={props.size === 'desktop' ? "row m-0" : "row m-0 justify-content-between"}>
                {/* <div className={props.size === 'desktop' ? `col-md-3 pl-0` : ''}>
                    <b>{job.from.branch.name} </b>
                </div> */}
                <div className={props.size === 'desktop' ? `col-md-2 pl-0` : ''}>
                    <b>{moment(job.to.timestamp).format("HH:mm")}</b>
                </div>
                <div className={props.size === 'desktop' ? `col-md-4` : ''}>
                    <span style={{ color: 'grey' }}>{moment(job.to.timestamp).format(" DD/MM/YYYY")}</span>
                </div >
                <div className={props.size === 'desktop' ? `col-md-3` : ''}>
                    <span style={{ color: 'grey' }}>{job.to.zone.name}</span>
                </div>
                <div className={props.size === 'desktop' ? `col-md-3` : ''}>
                    <span style={{ color: 'grey' }}>{job.sender.method.name}</span>
                </div>
            </div>
        )
    )
}

const StatusList = (props) => {
    let job = props.job
    return (
        <div >
            <div className="row m-0" >
                {
                    _.isArray(job.status) &&
                    _.map(job.status, (s, i) => {
                        let isActive = job.status.length - 1 == i;
                        let bgColor = "";
                        if (isActive) {
                            if (s.name == "new") {
                                bgColor = "warning";
                            } else if (s.name == "edit") {
                                bgColor = "warning";
                            } else if (s.name == "void") {
                                bgColor = "danger";
                            }
                        }

                        return (
                            <JobStatus
                                status={s}
                                isActive={isActive}
                                bgColor={bgColor}
                                i={i}
                                size={props.size}
                            />
                        );
                    })}
            </div>
        </div>
    )
}

const JobStatus = ({ status, isActive, bgColor, i, size }) => {
    let deatail = <span className={`badge badge-${bgColor !== "" ? bgColor : 'secondary'} `} >
        {status.name} {moment(status.timestamp).format("HH:mm")}</span>
    if (size === 'desktop') {
        return (
            <h5
                // className={i !== 0 ? 'pl-1' : ''}
                className='pl-1'
            >
                {deatail}
            </h5>
        )
    }
    else {
        return (
            <h6 className='pl-1'>
                {deatail}
            </h6>
        )
    }

    // return (
    //     [<span style={{ color: isActive ? '' : "gray" }}>{status.name}{" "} </span>,
    //     <span style={{ color: isActive ? '' : "gray", marginLeft: 4 }}>
    //         {" "}
    //         {moment(status.timestamp).format("HH:mm")}
    //     </span>]
    // )
}