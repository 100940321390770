import React from 'react';
import { connect } from 'react-redux'
import _ from 'lodash'
import { Route, Switch } from "react-router-dom";
import LiveScore from './LiveScore'
import LiveTable from './LiveTable'
import LiveTableDetail from './LiveTableDetail'



class LiveScoreMain extends React.Component {
    state = {}
    render() {
        const { match } = this.props
        return (
            <div className="page-title-box" style={{ minHeight: 500 }}>
                <div className="row m-0">
                    <div className="col-12 col-xl-6 offset-xl-3 col-md-8 offset-md-2" style={{ padding: 0 }}>
                        <Switch>
                            <Route path={`${match.url}/tableInfo/:customerId/:tableNo`} render={(props) => <LiveTableDetail {...props} />} />
                            <Route path={`${match.url}/liveTable/:customerId`} render={(props) => <LiveTable {...props} />} />
                            <Route exact path={`${match.url}`} render={(props) => <LiveScore {...props} />} />
                        </Switch>
                    </div>
                </div>
            </div>
        );
    }
}


const mapStateToProps = (state) => {
    return {
        // hqInfo: state.HQ_Info,
        // branches: state.branches
    }
}

export default connect(mapStateToProps)(LiveScoreMain)