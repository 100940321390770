import { departmentsRef } from '../../firebase/firesbaseRefs'
import _ from 'lodash'
import * as type from '../actions/type'



//Menu
export function fetchDapartments(user, cb) { //FETCH ลูก
    return (dispatch, getState) => {
        departmentsRef.child(user.hqId || user.customerId).on('value', (snapshot) => {
            let department = coumputeMenu(snapshot.val())
            let sorted = _.orderBy(department, 'row', 'asc')
            dispatch({
                type: type.MENU_FETCH_LIST,
                payload: sorted || []
            })

            cb && cb()

        })

    }
}

const coumputeMenu = (department) => { //แก้ Bug Printer Null
    _.forEach(department, (group) => {
        let printer = {}
        _.forEach(group.printer, (bool, key) => {
            if (bool !== undefined) {
                printer[key] = bool
            }
        })
        if (!_.isEmpty(printer)) {
            group.printer = printer
        }
        _.forEach(group.items, (item) => {
            let itemPrinter = {}
            _.forEach((item.meta && item.meta.printer) || [], (bool, key) => {
                if (bool !== undefined) {
                    itemPrinter[key] = bool
                }
            })
            if (!_.isEmpty(itemPrinter)) {
                item.meta.printer = itemPrinter
            }
        })
    })
    return department

}