import React from 'react'
import { connect } from 'react-redux'
import * as firebaseRefs from '../../shared/firebase/firesbaseRefs'
import _ from 'lodash'
import { JobCardDesktop, JobCardMobile } from '../../shared/components/JobCard'
import MenuListModal from '../../shared/components/MenuListModal'

class ActiveJobs extends React.Component {

    state = {
        activeJobs: {},
        selectedJob: {},
        loaded: false

    }

    componentDidMount = () => {
        const { customerId } = this.props
        this.fetchActiveJobs(customerId)
    }

    componentWillReceiveProps(nextProps) {
        const { customerId } = nextProps
        if (this.subscribe) {
            this.subscribe.off()
        }
        this.fetchActiveJobs(customerId)
    }

    fetchActiveJobs = (customerId) => {
        const { user } = this.props

        this.subscribe = firebaseRefs.jobsDeliveryRef.child(user.hqId || user.customerId);

        this.subscribe.on('value', (snapshot) => {
            let list = snapshot.val();
            let filteredJobs = _.filter(list, i => i.from.branch.customerId === customerId)
            let sortedData = _.orderBy(filteredJobs, 'to.timestamp', 'asc')
            this.setState({
                activeJobs: sortedData,
                loaded: true
            })
        })

    }

    componentWillUnmount() {
        if (this.subscribe) {
            this.subscribe.off()
        }
    }

    viewJobMenu = (job) => {
        this.setState({
            selectedJob: job
        })
    }


    render() {
        const { activeJobs, selectedJob, loaded } = this.state

        if (_.size(activeJobs) < 1 && loaded === true) {
            return (
                <div className="mt-5">
                    <h2 style={{ color: 'grey' }}>ไม่มีรายการ</h2>
                </div>
            )
        }
        return (
            <div>
                <MenuListModal job={selectedJob} />
                {
                    _.map(activeJobs, job => {
                        return (
                            <div key={job.jobId}>
                                <JobCardDesktop job={job} selectJob={() => this.viewJobMenu(job)} />
                                <JobCardMobile job={job} selectJob={() => this.viewJobMenu(job)} />
                            </div>
                        )
                    })
                }

            </div >
        )
    }

}




const mapStateToProps = (state) => {
    return {
        user: state.user.loggedIn

    }
}

export default connect(mapStateToProps)(ActiveJobs)