import * as type from '../../actions/type'
import _ from 'lodash'

const reducer = (state = {}, action) => {
    switch (action.type) {
        case type.MENU_FETCH_LIST:
            return { ...state, groups: action.payload }
        default:
            return state
    }
}

export default reducer