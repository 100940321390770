import React from 'react';
import DateRangePickerModal from './DateRangePickerModal'
import moment from 'moment';
import _ from 'lodash'
import CatalogCard from './CatalogCard'
import ToppingCard from './ToppingCard'
import DeletedMenuCard from './DeletedMenuCard'
import MediaQuery from 'react-responsive';
import swal from 'sweetalert'
import * as firebaseDb from '../../shared/firebase/firesbaseRefs'
import { connect } from 'react-redux'

class ProductReport extends React.Component {
    state = {
        startDate: new Date(),
        endDate: new Date(),
        startDateString: moment().format('YYYY-MM-DD'),
        endDateString: moment().format('YYYY-MM-DD'),

        menu: [],
        DeletedMenu: [],
        topping: [],

        viewMode: 'catalog',
        loading: true
    }
    setViewMode = (mode) => {
        this.setState({
            viewMode: mode
        })
    }

    componentDidMount() {
        const { customerId } = this.props
        this.fetch(customerId)
    }
    componentWillReceiveProps(nextProps) {
        const { customerId } = nextProps
        this.fetch(customerId)
    }

    fetch = (customerId) => {
        const { startDateString, endDateString } = this.state
        const { menu, topping } = this.props
        this.setState({
            loading: true
        })

        firebaseDb.receiptsDeliveryRef
            .where('from.branch.customerId', '==', customerId)
            .where('businessDate', '>=', startDateString)
            .where('businessDate', '<=', endDateString)
            .get()
            .then((querySnapshot) => {
                let arr = []
                querySnapshot.forEach(function (doc) {
                    arr.push(doc.data())
                });

                let itemsObj = {}
                let toppingObj = {}

                _.forEach(arr, (bill) => {
                    _.map(bill.reducer.cart.items, (item) => {
                        if (!itemsObj[item.uuid]) {
                            itemsObj[item.uuid] = {
                                discountedPrice: 0,
                                qty: 0,
                                uuid: item.uuid,
                                name: item.name,
                                type: 'item'
                            }
                        }

                        itemsObj[item.uuid].discountedPrice += item.discountedPrice
                        itemsObj[item.uuid].qty += item.qty

                        _.map(item.toppingList, (topping) => {
                            if (!toppingObj[item.uuid]) {
                                toppingObj[topping.uuid] = {
                                    qty: 0,
                                    uuid: topping.uuid,
                                    name: topping.name,
                                    type: 'item'

                                }
                            }

                            toppingObj[topping.uuid].qty += topping.qty

                        })


                    })
                })


                let computedMenu = []
                let computedTopping = []
                //MENU
                _.forEach(menu, (group) => {
                    let itemFound = []

                    _.forEach(group.items, (item) => {
                        if (itemsObj[item.uuid]) {
                            itemFound.push(itemsObj[item.uuid])
                        }
                        _.forEach(item.subItems, (subItem) => {
                            if (itemsObj[subItem.uuid]) {
                                itemFound.push(itemsObj[subItem.uuid])
                            }
                        })
                    })
                    if (itemFound.length > 0) {
                        let groupData = {
                            name: group.name,
                            uuid: group.uuid,
                            row: group.row || 99999,
                            type: 'group',
                            items: itemFound

                        }
                        computedMenu.push(groupData)
                    }
                })

                //TOPPING
                _.forEach(topping, (group) => {
                    let itemFound = []

                    _.forEach(group.items, (item) => {
                        if (toppingObj[item.uuid]) {
                            itemFound.push(toppingObj[item.uuid])
                        }
                    })
                    if (itemFound.length > 0) {
                        let groupData = {
                            name: group.name,
                            uuid: group.uuid,
                            row: group.row || 99999,
                            type: 'group',
                            items: itemFound

                        }
                        computedTopping.push(groupData)
                    }
                })

                this.setState({
                    menu: computedMenu,
                    topping: computedTopping,
                    loading: false

                })

                //TODO:ถ้าไม่มีกลุ่ม โยนไปที่ deleted menu

            })

    }

    dateObjToString = (date) => {
        let month = date.getMonth() + 1
        if (month < 10) {
            month = '0' + month
        }
        const dateString = `${date.getFullYear().toString()}-${month.toString()}-${date.getDate() < 10 ? ('0' + date.getDate().toString()) : date.getDate().toString()}`
        return dateString
    }
    saveDates = (startDate, endDate) => {
        const { customerId } = this.props
        const startDateString = this.dateObjToString(startDate)
        const endDateString = this.dateObjToString(endDate)
        let diff = moment(endDateString).diff(moment(startDateString), 'days')
        diff++
        if (diff > 90) {
            swal("เกิดข้อผิดพลาด", 'กรุณาเลือกช่วงเวลาไม่เกิน 90 วัน', "error");
            return
        }

        this.setState({
            startDate,
            endDate,
            startDateString,
            endDateString,

        })

        setTimeout(() => {
            this.fetch(customerId)
        }, 500)
    }

    getDateInput = (mode) => {
        const { startDateString, endDateString, } = this.state
        return (
            <div className={`input-group bootstrap-touchspin bootstrap-touchspin-injected ${mode === 'mobile' ? '' : 'col-md-6'}`}
                // style={mode === 'mobile' ? { padding: 0 } : {}}
                onClick={() => window.$("#dateButton").click()
                } >
                <input type="text" value={`${moment(startDateString).format('DD/MM/YYYY')}  ถึงวันที่  ${moment(endDateString).format('DD/MM/YYYY')}`} name="demo1" className="form-control" disabled />
                <span className=" bootstrap-touchspin-postfix input-group-append">
                </span>
                <span className="input-group-btn input-group-append">
                    <button className="btn btn-info bootstrap-touchspin-up" style={{ height: 38 }} id="dateButton" type="button" data-toggle="modal" data-target="#myModal" ><i className="mdi mdi-calendar"></i></button>
                </span>
            </div >
        )
    }
    getButtonGroup = () => {
        const { viewMode } = this.state
        return (
            <div className="btn-group btn-group-toggle" >
                <label class={`btn btn-primary ${viewMode === 'catalog' ? 'active' : ''}`} style={{ minWidth: 130 }}>
                    <input type="radio" onClick={() => this.setViewMode('catalog')} /> Menu
        </label>
                <label class={`btn btn-primary ${viewMode === 'topping' ? 'active' : ''}`} style={{ minWidth: 130 }}>
                    <input type="radio" onClick={() => this.setViewMode('topping')} /> Topping
        </label>
                {/* <label class={`btn btn-primary ${viewMode === 'deleted' ? 'active' : ''}`}>
                    <input type="radio" onClick={() => this.setViewMode('deleted')} /> Deleted Menu
        </label> */}
            </div>
        )
    }
    render() {
        const { startDate, endDate, menu, topping, DeletedMenu, viewMode, loading } = this.state

        if (loading) {
            return (
                <div id="preloader" >
                    <div id="status" >
                        <div className="spinner"></div>
                    </div>
                </div>
            )
        }
        return (
            <div>
                <DateRangePickerModal saveDates={this.saveDates}
                    startDate={startDate} endDate={endDate}
                />
                <MediaQuery maxDeviceWidth={500}>
                    <div style={{ marginTop: 5 }}></div>
                    {this.getDateInput('mobile')}
                    <div style={{ marginTop: 15 }}></div>
                    <center>{this.getButtonGroup()}</center>

                </MediaQuery>
                <MediaQuery minDeviceWidth={501}>
                    <div className="row m-0">
                        <div className="col-md-10 offset-md-1">

                            <div className="card" style={{ marginTop: 10 }}>
                                <div className="card-body">
                                    <div className="row m-0">
                                        {this.getDateInput()}
                                        <div className="col-md-6 text-center" >
                                            {this.getButtonGroup()}
                                        </div>
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>
                </MediaQuery>

                <div style={{ marginTop: 15 }}></div>
                <div className="row m-0">
                    <div className=" col-12 col-xl-10 offset-xl-1 p-0">
                        {
                            viewMode === 'catalog' && <CatalogCard menu={menu} />
                        }
                        {
                            viewMode === 'topping' && <CatalogCard menu={topping} isTopping={true} />
                        }
                        {/* {
                            viewMode === 'topping' && <ToppingCard ToppingGroups={ToppingGroups} />
                        }
                        {
                            viewMode === 'deleted' && <DeletedMenuCard DeletedMenu={DeletedMenu} />
                        } */}
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        menu: state.menu.groups || {},
        topping: state.topping.groups || {},
    }
}


export default connect(mapStateToProps)(ProductReport)