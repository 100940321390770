import { createSelector } from "reselect";
import _ from 'lodash'

const branches = state => state.branches;

export const branchesObjSelector = createSelector(
    [branches],
    (branch) => {
        let branchesObj = {}

        _.map(branch, data => {
            branchesObj[data.uuid] = data
        })
        
        return branchesObj
    }
)