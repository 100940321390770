import React from 'react'
import moment from 'moment'
import { connect } from 'react-redux'
import _ from 'lodash'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import 'react-datepicker/dist/react-datepicker-cssmodules.css'
import SummaryCard from '../../shared/components/SummaryCard'
import MediaQuery from 'react-responsive';
import DatePickerModal from '../Dashboard/DatePickerModal'
import * as firebaseDb from '../../shared/firebase/firesbaseRefs'
import { JobCardDesktop, JobCardMobile } from '../../shared/components/JobCard'
import MenuListModal from '../../shared/components/MenuListModal'
const dateModeStyle = { width: '35%' }

class BillsReport extends React.Component {

    state = {
        filterMode: 'date', //month,year
        date: moment().format('YYYY-MM-DD'),
        dateObj: Date.now(),
        bills: [],
        resultDate: '',
        resultTimestamp: '',
        sumGrandTotal: 0,
        sumBills: 0,
        showMonthCard: false,

        selectedJob: {},
        loading: true

    }

    componentDidMount() {
        const { customerId } = this.props
        this.fetch(this.state.date, customerId)
    }
    componentWillReceiveProps(nextProps) {
        const { customerId } = nextProps
        this.fetch(this.state.date, customerId)
    }
    dateObjToString = (date) => {
        let month = date.getMonth() + 1
        if (month < 10) {
            month = '0' + month
        }
        const dateString = `${date.getFullYear().toString()}-${month.toString()}-${date.getDate() < 10 ? ('0' + date.getDate().toString()) : date.getDate().toString()}`
        return dateString
    }
    saveDates = (dateObj) => {
        const date = this.dateObjToString(dateObj)
        this.setState({
            dateObj,
            date
        })

        this.fetch(date)
    }

    convertNumber = (number) => {
        let res = parseInt((number).toFixed(0)).toLocaleString()
        return _.isNaN(res) ? 0 : res
    }

    getCurrentMonthDays = () => {
        const { date } = this.state
        let days = 0
        let startOfMonth = moment(date).startOf('month')
        if (moment(date).format('YYYY-MM') !== moment().format('YYYY-MM')) {
            days = moment(date).endOf('month').diff(startOfMonth, 'days') + 1
        } else {
            days = moment(moment().format('YYYY-MM-DD')).diff(startOfMonth, 'days') + 1
        }
        return days
    }

    getTimePickers = (mode) => {
        const { filterMode, date, dateObj } = this.state
        return (
            <div style={mode === 'mobile' ? {} : { marginTop: 6 }}>
                {filterMode === 'date' &&
                    mode !== 'mobile' &&
                    <DatePicker
                        className="form-control"
                        selected={dateObj}
                        onChange={this.setDate}
                        dateFormat="dd/MM/YYYY"
                        id="date-pick"
                    />
                }
                {
                    filterMode === 'date' && mode === 'mobile' &&
                    <div className={`input-group bootstrap-touchspin bootstrap-touchspin-injected`}
                        onClick={() => window.$("#dateButton").click()
                        } >
                        <input type="text" value={`${moment(date).format('DD/MM/YYYY')}`} name="demo1" className="form-control" disabled />
                        <div class="input-group-append">
                            <button class="btn btn-primary" type="button"><i class="mdi mdi-calendar"></i></button>
                        </div>
                        <button style={{ height: 38 }} id="dateButton" type="button" data-toggle="modal" data-target="#myModal" style={{ visibility: 'hidden' }}></button>
                    </div >
                }
                {filterMode === 'month' &&
                    <input className="form-control"
                        value={date} type='month'
                        onChange={e => this.handleSetMonth(e)} />
                }
                {filterMode === 'year' &&
                    <select className="form-control"
                        onChange={e => {
                            if (e.target.value === "") return
                            this.handleSetMonth(e)
                        }} >
                        <option value="" >Select Year</option>
                        <option value="2018" >2018</option>
                        <option value="2019" >2019</option>
                    </select>
                }
            </div>
        )
    }

    // getFilterButtons = () => {
    //     const { filterMode } = this.state
    //     return (
    //         <div className="btn-group btn-group-toggle" data-toggle="buttons" style={{ height: 38 }}>
    //             <ModeButton mode="date" filterMode={filterMode} onClick={this.changeDateMode} />
    //             <ModeButton mode="month" filterMode={filterMode} onClick={this.changeDateMode} />
    //             <ModeButton mode="year" filterMode={filterMode} onClick={this.changeDateMode} />
    //         </div>
    //     )
    // }

    getReloadButton = (mode) => {
        const { date } = this.state
        if (mode === 'mobile') {
            return <a className="waves-effect waves-light" href="#"
                style={{ fontSize: 20, paddingLeft: 10 }}
                onClick={e => {
                    e.stopPropagation()
                    e.preventDefault()
                    this.fetch(date)
                }} role="button"><i className="mdi mdi-reload"></i>Reload</a>
        }
        return (
            <div style={{ marginLeft: 5 }}>
                <a className="btn btn-info waves-effect waves-light" href="#"
                    onClick={e => {
                        e.stopPropagation()
                        e.preventDefault()
                        this.fetch(date)
                    }} role="button"><i className="mdi mdi-reload"></i></a>
            </div>
        )
    }
    getAvgValue = (value) => {
        let computed = parseInt((this.state.sumGrandTotal / value).toFixed(0)).toLocaleString() || 0
        if (computed.toString() === 'NaN') {
            computed = 0
        }
        return computed
    }

    fetch = (date, forceCustomerId) => {
        let customerId = forceCustomerId || this.props.customerId
        let sumGrandTotal = 0
        let sumBills = 0

        this.setState({
            loading: true
        })

        firebaseDb.receiptsDeliveryRef
            .where('from.branch.customerId', '==', customerId)
            .where('businessDate', '==', date)
            .get()
            .then((querySnapshot) => {
                let arr = []
                querySnapshot.forEach(function (doc) {
                    arr.push(doc.data())
                });

                _.forEach(arr, (i) => { //FOR CARD
                    sumGrandTotal += i.grandTotal || 0
                    sumBills++
                })

                let sortedData = _.orderBy(arr, 'to.timestamp', 'asc')

                this.setState({
                    bills: sortedData,
                    resultDate: this.getResultDate(date),
                    resultTimestamp: moment().toISOString(),
                    sumGrandTotal,
                    sumBills,
                    loading: false

                    // sumGuest
                })
            })
            .catch(err => {
                alert(err)
            })
    }

    getResultDate = (date) => {
        const { filterMode } = this.state
        let time = ''
        switch (filterMode) {
            case "date":
                time = moment(date).format('DD MMM YYYY')
                break
            case "month":
                time = moment(date).format('MMM YYYY')
                break
            case "year":
                time = moment(date).format('YYYY')
                break
        }
        return time

    }

    setDate = (dateObj) => {
        const date = this.dateObjToString(dateObj)

        this.fetch(date)
        this.setState({
            date,
            dateObj
        })
    }


    changeDateMode = (mode) => {
        let date = moment().format('YYYY-MM-DD')
        if (mode === 'month') {
            date = moment().format('YYYY-MM')
        }
        if (mode === 'year') {
            date = '2019'
        }
        this.fetch(date)
        this.setState({
            filterMode: mode,
            date,
            showMonthCard: mode === 'month' ? true : false,
            // showMonthCard: false,
            dateObj: Date.now()
        })
    }
    handleSetMonth = (e) => {
        this.fetch(e.target.value)
        this.setState({ date: e.target.value, showMonthCard: true })
    }

    viewJobMenu = (job) => {
        this.setState({
            selectedJob: job
        })
    }

    render() {
        const { date, dateObj, filterMode, bills, resultDate, resultTimestamp, sumGrandTotal, sumBills, selectedJob, loading } = this.state
        const days = this.getCurrentMonthDays()
        let itemIndex = 0

        if (loading) {
            return (
                <div id="preloader" >
                    <div id="status" >
                        <div className="spinner"></div>
                    </div>
                </div>
            )
        }
        return (

            <div className="page-title-box" style={{ minHeight: 500 }}>

                <MenuListModal job={selectedJob} />

                {/* MOBILE */}
                <MediaQuery maxDeviceWidth={500}>
                    <DatePickerModal
                        saveDates={this.saveDates}
                        startDate={dateObj}
                    />
                    <div className="row justify-content-between m-0">
                        <div>
                            {this.getTimePickers('mobile')}
                            {/* <h3>บิลขาย</h3> */}
                        </div>
                        <div className='align-self-center'>{this.getReloadButton('mobile')}</div>
                    </div>
                    <div style={{ marginTop: 10 }}></div>
                    <div><b><span style={{ fontSize: '0.8em', fontWeight: 'lighter', color: 'grey', fontStyle: 'italic' }}>Result for: {resultDate}</span></b></div>
                    <div className="mb-2"></div>
                    {
                        !_.isEmpty(bills) &&
                        <div>
                            {
                                _.map(bills, job => {
                                    return (
                                        <div key={job.jobId}>
                                            <JobCardMobile job={job} selectJob={() => this.viewJobMenu(job)} />
                                        </div>
                                    )
                                })
                            }
                        </div>
                    }
                    <div><b><span style={{ fontSize: '0.8em', fontWeight: 'lighter', color: 'grey', fontStyle: 'italic' }}>ค้นหาเมื่อ: {resultTimestamp}</span></b></div>


                </MediaQuery>
                {/* DESKTOP */}
                <MediaQuery minDeviceWidth={501}>

                    <div className="row m-0" style={{ marginTop: 15 }}>
                        <div className="col-md-10 offset-md-1">

                            <div className="row m-0 mb-2">
                                <div className="col-md-10 offset-md-1">
                                    <div className="row" style={{ margin: 0, minHeight: 100, flex: 1 }}>
                                        <div className="align-self-center" style={{ paddingLeft: 0 }}>
                                            <div className="row m-0" >
                                                <div>
                                                    {this.getTimePickers()}
                                                </div>
                                                <div style={{ marginTop: 7 }}>
                                                    {this.getReloadButton()}
                                                </div>
                                            </div>

                                        </div>

                                        <SummaryCard title={sumGrandTotal.toLocaleString()} subtitle="Grand Total" />
                                        <SummaryCard title={`${sumBills.toLocaleString()}`} subtitle={`Bills`} />
                                    </div>
                                    <div><b><span style={{ fontSize: '0.8em', fontWeight: 'lighter', color: 'grey', fontStyle: 'italic' }}>Result for: {resultDate}</span></b></div>
                                </div>
                            </div>

                            {
                                !_.isEmpty(bills) &&
                                <div>
                                    {
                                        _.map(bills, job => {
                                            return (
                                                <div key={job.jobId}>
                                                    <JobCardDesktop job={job} selectJob={() => this.viewJobMenu(job)} />
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            }
                            <div><b><span style={{ fontSize: '0.8em', fontWeight: 'lighter', color: 'grey', fontStyle: 'italic' }}>ค้นหาเมื่อ: {resultTimestamp}</span></b></div>
                        </div >
                    </div>
                </MediaQuery>
            </div>
        );
    }

}

const mapStateToProps = (state) => {
    return {
        branches: state.branches,
        isHq: state.shop.isHq || false,
        loggedInUser: state.user.loggedIn
    }
}


export default connect(mapStateToProps)(BillsReport)

const ModeButton = ({ mode, onClick, filterMode }) => {
    return (
        <label className={"btn btn-primary " + (filterMode === mode ? 'active' : '')} style={dateModeStyle} onClick={() => onClick(mode)}>
            <input type="radio" name="options" />{getModeName(mode)}
        </label>
    )
}

const getModeName = (mode) => {
    let name = 'วัน'
    switch (mode) {
        case 'month':
            name = 'เดือน'
            break
        case 'year':
            name = 'ปี'
            break
    }

    return name
}

