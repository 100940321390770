import { departmentsToppingRef } from '../../firebase/firesbaseRefs'
import _ from 'lodash'
import * as type from '../actions/type'

export function fetchTopping(user) {
    return (dispatch, getState) => {

        departmentsToppingRef.child(user.hqId || user.customerId)
            .on('value', (snapshot) => {
                let topping = snapshot.val();
                let sorted = _.orderBy(topping, 'row', 'asc')

                dispatch({
                    type: type.TOPPING_FETCH_LIST,
                    payload: sorted || []
                })
            })
    }
}