
import { combineReducers } from 'redux'
import user from './user'
import branches from './branches'
import HQ_Info from './HQ_Info'
import loading from './loadingReducer'
import shop from './shop/shopReducer'
import menu from './menu/menuReducer'
import topping from './topping/toppingReducer'


export default combineReducers({
    user,
    HQ_Info,
    branches,
    loading,
    shop,
    menu,
    topping
})
