import React from 'react'

import { Route, Switch } from "react-router-dom";
import Dashboard from '../view/Dashboard/Dashboard'
import Sales from '../view/Sales'
import LiveScore from '../view/LiveScore'

import { css } from 'react-emotion';
// First way to import
import { BeatLoader } from 'react-spinners';
import { connect } from 'react-redux'
import { withRouter } from "react-router-dom";
import MangeUser from '../view/ManageUser'
import MediaQuery from 'react-responsive';
import ResetPassword from '../view/ResetPassword'

const override = css`
    display: block; 
    margin: 0 auto;
    border-color: red;
`;

class MainContent extends React.Component {



    render() {
        const { loading, loggedInUser } = this.props
        if (loading) {
            return (
                // <div>
                //     <MediaQuery maxDeviceWidth={500}>
                //         <LoadSpinner mode='mobile' loading={loading} />
                //     </MediaQuery>
                //     <MediaQuery minDeviceWidth={501}>
                //         <LoadSpinner loading={loading} />
                //     </MediaQuery>
                // </div>
                <div id="preloader" >
                    <div id="status" >
                        <div className="spinner"></div>
                    </div>
                </div>
            )
        }
        return (
            <Switch>
                <Route path='/resetPassword' render={(props) => <ResetPassword {...props} loggedInUser={loggedInUser} />} />
                <Route path='/liveScore' render={(props) => <LiveScore {...props} />} />
                <Route path='/manageUser' render={(props) => <MangeUser {...props} />} />
                <Route path='/branch/:branchUid' render={(props) => <Sales {...props} />} />
                {/* <Route path='/dashboard' render={(props) => <h1>HELLO NICELOOP</h1>} /> */}
                <Route exact path='/' render={(props) => <Dashboard />} />
            </Switch>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        loading: state.loading.initial,
        loggedInUser: state.user.loggedIn
    }
}

// const LoadSpinner = ({ mode, loading }) => {
//     return (
//         <div className='sweet-loading' style={{
//             position: 'absolute',
//             left: mode === 'mobile' ? '35%' : '55%',
//             top: '45%',
//             position: 'absolute',
//             zIndex: 101,
//             marginLeft: -16,
//             marginRop: -16,
//         }}>
//             <BeatLoader
//                 className={override}
//                 sizeUnit={"px"}
//                 size={50}
//                 color={'#123abc'}
//                 loading={loading}
//             />
//         </div>
//     )
// }

export default withRouter(connect(mapStateToProps)(MainContent))