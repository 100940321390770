import React from 'react'
import SalesSummary from './SalesSummary'
import ProductSummary from './ProductSummary'
import ActiveJobs from './ActiveJobs'
import { withRouter } from "react-router-dom";
import { connect } from 'react-redux'
import * as branchesSelector from '../../shared/selector/branchesSelector'
import BillsReport from './BillsReport';
// import { bindActionCreators } from 'redux'

class Sales extends React.Component {
    state = {
        branchId: '',
        customerId: '',
        viewPage: 'activeJobs'
    }

    componentDidMount() {
        const { match, branchesObj } = this.props
        let branch = branchesObj[match.params.branchUid]
        this.setState({
            branchId: branch.branchId,
            customerId: branch.customerId
        })
    }

    componentWillReceiveProps(nextProps) {
        const { match, branchesObj } = nextProps
        let branch = branchesObj[match.params.branchUid]
        this.setState({
            branchId: branch.branchId,
            customerId: branch.customerId
        })
    }

    view = (e, viewPage) => {
        e.stopPropagation()
        e.preventDefault()
        this.setState({
            viewPage
        })
    }

    render() {
        const { match } = this.props
        const { branchId, customerId, viewPage } = this.state

        if (branchId === '' || customerId === '') {
            return <h1>Loading...</h1>
        }

        return (
            <div className="page-title-box" >
                <ul
                    className="nav nav-tabs nav-tabs-custom nav-justified"
                    // class="nav nav-pills nav-bg-custom nav-justified"
                    role="tablist">
                    <li className="nav-item waves-effect waves-light">
                        <a className={`nav-link ${viewPage === 'activeJobs' ? 'active show' : ''}`} href="#" onClick={(e) => this.view(e, 'activeJobs')}>
                            <span className=" d-md-block">Live</span>
                        </a>
                    </li>
                    <li className="nav-item waves-effect waves-light">
                        <a className={`nav-link ${viewPage === 'sales' ? 'active show' : ''}`} href="#" onClick={(e) => this.view(e, 'sales')}>
                            <span className=" d-md-block">ยอดขาย</span>
                        </a>
                    </li>
                    <li className="nav-item waves-effect waves-light">
                        <a className={`nav-link ${viewPage === 'bills' ? 'active show' : ''}`} href="#" onClick={(e) => this.view(e, 'bills')}>
                            <span className=" d-md-block">บิลขาย</span>
                        </a>
                    </li>
                    <li className="nav-item waves-effect waves-light">
                        <a className={`nav-link ${viewPage === 'product' ? 'active show' : ''}`} href="#" onClick={(e) => this.view(e, 'product')}>
                            <span className=" d-md-block">สินค้า</span>
                        </a>
                    </li>
                   

                </ul>

                <div className="tab-content">

                    <div style={{ padding: 0, paddingTop: 16 }}>
                        {
                            viewPage === 'sales' &&
                            <SalesSummary match={match} customerId={customerId} />
                        }
                        {
                            viewPage === 'bills' &&
                            <BillsReport match={match} branchId={branchId} customerId={customerId} />
                        }
                        {
                            viewPage === 'product' &&
                            <ProductSummary match={match} customerId={customerId} />
                        }
                        {
                            viewPage === 'activeJobs' &&
                            <ActiveJobs match={match} branchId={branchId} customerId={customerId} />
                        }
                    </div>

                </div>



            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        hqInfo: state.HQ_Info,
        branches: state.branches,
        loggedInUser: state.user.loggedIn,
        branchesObj: branchesSelector.branchesObjSelector(state)

    }
}

export default withRouter(connect(mapStateToProps)(Sales))