import React from 'react';
// import { signUp } from '../../shared/api/authen'
import documentClient from '../../shared/api/dynamoDb'
import swal from 'sweetalert'
import { connect } from 'react-redux'
import async from 'async'

const initData = {
    username: '',
    password: '',
    rePassword: ''
}
class AddUserModal extends React.Component {
    state = initData

    handleOnChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    validate = () => {
        const { username, password, rePassword } = this.state
        if (username === '' || password === '' || rePassword === '') {
            swal("เกิดข้อผิดพลาด", "กรุณากรอกข้อมูลให้ครบถ้วน", "error");
            return true
        }
        if (password.length < 6 || rePassword.length < 6) {
            swal("เกิดข้อผิดพลาด", "กรุณากรอก Password อย่างน้อย 6 ตัวอักษร", "error");
            return true
        }
        if (password != rePassword) {
            swal("เกิดข้อผิดพลาด", "กรุณากรอก Password ให้ตรงกันทั้งสองช่อง", "error");
            return true
        }

        return false

    }

    createUser = () => {
        const { username, password } = this.state

        if (this.validate()) { //FAIL
            return
        }

        async.waterfall([
            (cb) => {
                // signUp({ username, password }, cb)
            },
            (cb) => {
                let params = {
                    TableName: "V1_HQ_Users",
                    Item: {
                        username: username,
                        customerId: this.props.loggedInUser.customerId,
                        role: 'user'
                    }
                };
                documentClient.put(params, function (err, data) {
                    if (err) {
                        console.log(err);
                        cb(err);
                    } else { 
                        cb();
                    }
                });
            }

        ], (err, result) => {
            if (err) {
                alert(err)
                return
            }
            swal("สำเร็จ !", "Create User Complete", "success");
            this.props.fetchUser()
            this.setState({
                ...initData
            })
        })
    }

    render() {
        const { username, password, rePassword } = this.state
        return (
            <div class="modal fade bs-example-modal-center" tabindex="-1" role="dialog"
                aria-labelledby="mySmallModalLabel" id="myModal">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title mt-0">Create User</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-hidden="true">x</button>
                        </div>
                        <div class="modal-body">
                            <div className="row mb-0">
                                <div className="col-md-8 offset-md-2">
                                    <small>Username</small>
                                    <input class="form-control" type="text" name="username" value={username} onChange={this.handleOnChange} />
                                </div>
                            </div>
                            <div className="row mb-0">
                                <div className="col-md-8 offset-md-2">
                                    <small>Password</small>
                                    <input class="form-control" type="password" name="password" value={password} onChange={this.handleOnChange} />
                                </div>
                            </div>
                            <div className="row" style={{ marginBottom: 15 }}>
                                <div className="col-md-8 offset-md-2">
                                    <small>Re-Enter Password</small>
                                    <input class="form-control" type="password" name="rePassword" value={rePassword} onChange={this.handleOnChange} />
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-success" onClick={this.createUser}>CREATE</button>
                            <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>

        );
    }
}

const mapStateToProps = (state) => {
    return {
        loggedInUser: state.user.loggedIn,
    }
}

// function mapDispatchToProps(dispatch) {
//     let actions = bindActionCreators({ ...hqInfoActions }, dispatch)
//     return {
//         actions,
//     }
// }

export default connect(mapStateToProps)(AddUserModal)