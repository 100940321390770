import React from 'react'
import moment from 'moment'
import { connect } from 'react-redux'
import documentClient from '../../shared/api/dynamoDb'
import async from 'async'
import _ from 'lodash'
import swal from 'sweetalert'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import 'react-datepicker/dist/react-datepicker-cssmodules.css'
import SummaryCard from '../../shared/components/SummaryCard'
import MediaQuery from 'react-responsive';
import DatePickerModal from './DatePickerModal'
import * as firebaseDb from '../../shared/firebase/firesbaseRefs'

const dateModeStyle = { width: '35%' }

class Dashboard extends React.Component {

    state = {
        filterMode: 'date', //month,year
        date: moment().format('YYYY-MM-DD'),
        dateObj: Date.now(),
        summary: {},
        resultDate: '',
        resultTimestamp: '',

        sumGrandTotal: 0,
        sumBills: 0,
        // sumGuest: 0,

        showMonthCard: false,
        loading: true
    }

    componentDidMount() {
        this.fetch(this.state.date)
    }
    dateObjToString = (date) => {
        let month = date.getMonth() + 1
        if (month < 10) {
            month = '0' + month
        }
        const dateString = `${date.getFullYear().toString()}-${month.toString()}-${date.getDate() < 10 ? ('0' + date.getDate().toString()) : date.getDate().toString()}`
        return dateString
    }
    saveDates = (dateObj) => {
        const date = this.dateObjToString(dateObj)
        this.setState({
            dateObj,
            date
        })

        this.fetch(date)
    }

    render() {
        const { date, dateObj, filterMode, summary, resultDate, resultTimestamp, sumGrandTotal, sumBills, sumGuest, showMonthCard, loading } = this.state
        const avgBills = this.getAvgValue(sumBills)
        // const avgGuest = this.getAvgValue(sumGuest)
        const days = this.getCurrentMonthDays()
        let itemIndex = 0
        return (

            <div className="page-title-box" style={{ minHeight: 500 }}>
                <DatePickerModal
                    saveDates={this.saveDates}
                    startDate={dateObj}
                />
                {
                    loading ?
                        <div id="preloader" >
                            <div id="status" >
                                <div className="spinner"></div>
                            </div>
                        </div> :
                        <div>
                            {/* MOBILE */}
                            <MediaQuery maxDeviceWidth={500}>

                                {/* <div className="row justify-content-between m-0">
    <div className='align-self-center'>{this.getReloadButton('mobile')}</div>
</div> */}
                                <div className="row m-0 justify-content-between" >
                                    <div  >
                                        {this.getTimePickers('mobile')}
                                    </div>
                                    <div >
                                        {this.getFilterButtons()}
                                    </div>
                                </div>
                                <ul className="list-group" style={{ paddingTop: 5 }}>

                                    {
                                        _.map(summary, (i, customerId) => {
                                            itemIndex++
                                            return (
                                                <li key={customerId}
                                                    className="list-group-item"
                                                    style={{ backgroundColor: itemIndex % 2 === 0 ? '#e6ecf7' : 'white' }}
                                                >
                                                    <div className=" d-flex justify-content-between align-items-center">
                                                        {i.name}
                                                        <span style={{ color: '#e2595f', fontSize: 18 }}>{this.convertNumber(i.grandTotal)}฿</span>
                                                    </div>

                                                </li>
                                            )
                                        })
                                    }
                                </ul>
                                <div style={{ marginTop: 10 }}></div>
                                <div><b><span style={{ fontSize: '0.8em', fontWeight: 'lighter', color: 'grey', fontStyle: 'italic' }}>ค้นหาเมื่อ: {resultTimestamp}</span></b></div>



                            </MediaQuery>
                            {/* DESKTOP */}
                            <MediaQuery minDeviceWidth={501}>

                                <div className="row m-0" style={{ marginTop: 15 }}>
                                    <div className="col-md-10 offset-md-1">
                                        <div className="row" style={{ margin: 0, minHeight: 100, flex: 1 }}>
                                            <div className="align-self-center" style={{ paddingLeft: 0 }}>
                                                <div className="row m-0" >
                                                    {this.getFilterButtons()}
                                                    {/* {this.getReloadButton()} */}
                                                </div>
                                                {this.getTimePickers()}
                                            </div>

                                            <SummaryCard title={sumGrandTotal.toLocaleString()} subtitle="Grand Total" />
                                            {/* <SummaryCard title={`${avgGuest}/Head`} subtitle={`${sumGuest.toLocaleString()} Customers`} /> */}
                                            {filterMode === 'month' && showMonthCard && <SummaryCard title={`${this.convertNumber(sumGrandTotal / days)}/Day`} subtitle={`Avg per day`} />}
                                            <SummaryCard title={`Avg ${avgBills}/Bill`} subtitle={`${sumBills.toLocaleString()} Bills`} />



                                        </div>
                                        {
                                            !_.isEmpty(summary) &&
                                            <div>
                                                <div><b><span style={{ fontSize: '0.8em', fontWeight: 'lighter', color: 'grey', fontStyle: 'italic' }}>Result for: {resultDate}</span></b></div>
                                                <div className="card" style={{ marginTop: 10 }}>
                                                    <div className="card-body">

                                                        <h3 className="mt-0 header-title">ภาพรวมสาขา</h3>
                                                        <div className="table-responsive">
                                                            <table className="table mb-0">
                                                                <thead className="thead-default">
                                                                    <tr>
                                                                        <th>ชื่อ</th>
                                                                        <th style={{ textAlign: 'right' }}>ยอดขาย</th>
                                                                        <th style={{ textAlign: 'right' }}>บิล</th>
                                                                        <th style={{ textAlign: 'right' }}>Avg/bill</th>
                                                                        {/* <th style={{ textAlign: 'right' }}>ลูกค้า</th> */}
                                                                        {/* <th style={{ textAlign: 'right' }}>Avg/ลูกค้า</th> */}
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {
                                                                        _.map(summary, (i, customerId) => {
                                                                            // let avgGuest = (i.avgGuest !== Infinity && i.avgGuest) || 0
                                                                            let avgBills = (i.avgBills !== Infinity && i.avgBills) || 0

                                                                            return (
                                                                                <tr key={customerId}>
                                                                                    <th scope="row">{i.name}</th>
                                                                                    <td style={{ textAlign: 'right' }}><span style={{ color: 'blue', fontWeight: 'bold' }}>{this.convertNumber(i.grandTotal)}</span></td>
                                                                                    <td style={{ textAlign: 'right' }}>{this.convertNumber(i.bills)}</td>
                                                                                    <td style={{ textAlign: 'right' }}>{this.convertNumber(avgBills)}</td>
                                                                                    {/* <td style={{ textAlign: 'right' }}>{this.convertNumber(i.guest)}</td> */}
                                                                                    {/* <td style={{ textAlign: 'right' }}>{this.convertNumber(avgGuest)}</td> */}
                                                                                </tr>
                                                                            )
                                                                        })
                                                                    }
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div><b><span style={{ fontSize: '0.8em', fontWeight: 'lighter', color: 'grey', fontStyle: 'italic' }}>ค้นหาเมื่อ: {resultTimestamp}</span></b></div>
                                            </div>
                                        }
                                    </div >
                                </div>
                            </MediaQuery>
                        </div>

                }
            </div>
        );
    }
    convertNumber = (number) => {
        let res = parseInt((number).toFixed(0)).toLocaleString()
        return _.isNaN(res) ? 0 : res
    }

    getCurrentMonthDays = () => {
        const { date } = this.state
        let days = 0
        let startOfMonth = moment(date).startOf('month')
        if (moment(date).format('YYYY-MM') !== moment().format('YYYY-MM')) {
            days = moment(date).endOf('month').diff(startOfMonth, 'days') + 1
        } else {
            days = moment(moment().format('YYYY-MM-DD')).diff(startOfMonth, 'days') + 1
        }
        return days
    }

    getTimePickers = (mode) => {
        const { filterMode, date, dateObj } = this.state
        return (
            <div style={mode === 'mobile' ? {} : { marginTop: 6 }}>
                {filterMode === 'date' &&
                    mode !== 'mobile' &&
                    <DatePicker
                        className="form-control"
                        selected={dateObj}
                        onChange={this.setDate}
                        dateFormat="dd/MM/YYYY"
                        id="date-pick"
                    />
                }
                {
                    filterMode === 'date' && mode === 'mobile' &&
                    <div
                        onClick={() => window.$("#dateButton").click()
                        } >
                        <input type="text" value={`${moment(date).format('DD/MM/YYYY')}`} name="demo1" className="form-control" style={{ width: 150 }} disabled />
                        <button style={{ height: 38 }} id="dateButton" type="button" data-toggle="modal" data-target="#myModal" style={{ visibility: 'hidden' }}></button>
                    </div >
                }
                {filterMode === 'month' &&
                    <input className="form-control"
                        value={date} type='month'
                        onChange={e => this.handleSetMonth(e)} />
                }
                {filterMode === 'year' &&
                    <select className="form-control"
                        onChange={e => {
                            if (e.target.value === "") return
                            this.handleSetMonth(e)
                        }} >
                        <option value="" >Select Year</option>
                        <option value="2018" >2018</option>
                        <option value="2019" >2019</option>
                    </select>
                }
            </div>
        )
    }

    getFilterButtons = () => {
        const { filterMode, date } = this.state
        return (
            <div className="btn-group btn-group-toggle" data-toggle="buttons" style={{ height: 38 }}>
                <ModeButton mode="date" filterMode={filterMode} onClick={this.changeDateMode} />
                <ModeButton mode="month" filterMode={filterMode} onClick={this.changeDateMode} />
                <ModeButton mode="year" filterMode={filterMode} onClick={this.changeDateMode} />
                <label className="btn btn-info" onClick={() => this.fetch(date)} style={{ width: 50 }}>
                    <input type="radio" name="options" /><i className="mdi mdi-reload"></i>
                </label>
            </div>
        )
    }

    getReloadButton = (mode) => {
        const { date } = this.state
        if (mode === 'mobile') {
            return <a className="waves-effect waves-light" href="#"
                style={{ fontSize: 20, paddingLeft: 10 }}
                onClick={e => {
                    e.stopPropagation()
                    e.preventDefault()
                    this.fetch(date)
                }} role="button"><i className="mdi mdi-reload"></i>Reload</a>
        }
        return (
            <div style={{ marginLeft: 5 }}>
                <a className="btn btn-info waves-effect waves-light" href="#"
                    onClick={e => {
                        e.stopPropagation()
                        e.preventDefault()
                        this.fetch(date)
                    }} role="button"><i className="mdi mdi-reload"></i></a>
            </div>
        )
    }
    getAvgValue = (value) => {
        let computed = parseInt((this.state.sumGrandTotal / value).toFixed(0)).toLocaleString() || 0
        if (computed.toString() === 'NaN') {
            computed = 0
        }
        return computed
    }

    fetch = (date) => {
        const { branches, isHq, loggedInUser } = this.props
        let raw = {}
        const branchObj = {}
        // let branchId = {}
        let sumGrandTotal = 0
        let sumBills = 0
        // let sumGuest = 0

        this.setState({
            loading: true
        })

        let filteredBranches = branches
        if (!isHq) { //ถ้าไม่ใช่ HQ ดูเฉพาะของตนเอง
            filteredBranches = _.filter(branches, i => {
                return i.customerId === loggedInUser.customerId
            })
        }
        async.each(filteredBranches, (branch, cb) => {
            const customerId = branch.customerId.toString()
            branchObj[customerId] = branch



            let ref = firebaseDb.firestoreLivescoreDeliveryRef
                .where('customerId', '==', customerId)

            let dateLenght = date.length
            let dateCondition = date
            if (dateLenght < 8) { // MONTH
                dateCondition = date + "-32"
                ref = ref
                    .where('businessDate', '>=', date)
                    .where('businessDate', '<=', dateCondition);
            }

            if (dateLenght < 5) { //YEAR
                dateCondition = date + "-12-32"
                ref = ref
                    .where('businessDate', '>=', date)
                    .where('businessDate', '<=', dateCondition);
            }
            if (dateLenght === 10) { //DAY
                ref = ref
                    .where('businessDate', '==', date)
            }

            ref.get()
                .then(async (querySnapshot) => {
                    let arr = []
                    querySnapshot.forEach(function (doc) {
                        arr.push(doc.data())
                    });
                    raw[customerId] = arr

                    cb()
                })
                .catch(err => {
                    raw[customerId] = []
                    cb()
                })
        }, (err, res) => {
            if (err) {
                swal("เกิดข้อผิดพลาด", err, "error");
            } else {
                let summary = {}

                _.map(raw, (data, customerId) => {
                    summary[customerId] = {
                        name: branchObj[customerId].name,
                        branchId: branchObj[customerId].branchId,
                        grandTotal: 0,
                        bills: 0,
                        avgBills: 0,
                        // guest: 0,
                        // avgGuest: 0
                    }
                    _.forEach(data, (i) => {
                        summary[customerId].grandTotal += i.grandTotal || 0
                        summary[customerId].bills += i.bills || 0
                        // summary[customerId].guest += i.guest || 0

                        //CARD
                        sumGrandTotal += i.grandTotal || 0
                        sumBills += i.bills || 0
                        // sumGuest += i.guest || 0
                    })

                    if (summary[customerId].bills !== 0) {
                        summary[customerId].avgBills = summary[customerId].grandTotal / summary[customerId].bills
                    }
                    // summary[customerId].avgGuest = summary[customerId].grandTotal / summary[customerId].guest
                })
                summary = _.orderBy(summary, 'branchId', 'asc')
                let resultTimestamp = moment().toISOString()

                let computedData = {
                    summary,
                    resultDate: this.getResultDate(date),
                    resultTimestamp,
                    sumGrandTotal,
                    sumBills,
                    // sumGuest
                }
                this.setState({
                    ...computedData,
                    loading: false
                })
                // done()
            }
        })
    }

    getResultDate = (date) => {
        const { filterMode } = this.state
        let time = ''
        switch (filterMode) {
            case "date":
                time = moment(date).format('DD MMM YYYY')
                break
            case "month":
                time = moment(date).format('MMM YYYY')
                break
            case "year":
                time = moment(date).format('YYYY')
                break
        }
        return time

    }

    setDate = (dateObj) => {
        const date = this.dateObjToString(dateObj)

        this.fetch(date)
        this.setState({
            date,
            dateObj
        })
    }


    changeDateMode = (mode) => {
        let date = moment().format('YYYY-MM-DD')
        if (mode === 'month') {
            date = moment().format('YYYY-MM')
        }
        if (mode === 'year') {
            date = '2019'
        }
        this.fetch(date)
        this.setState({
            filterMode: mode,
            date,
            showMonthCard: mode === 'month' ? true : false,
            // showMonthCard: false,
            dateObj: Date.now()
        })
    }
    handleSetMonth = (e) => {
        this.fetch(e.target.value)
        this.setState({ date: e.target.value, showMonthCard: true })
    }
}

const mapStateToProps = (state) => {
    return {
        branches: state.branches,
        isHq: state.shop.isHq || false,
        loggedInUser: state.user.loggedIn
    }
}


export default connect(mapStateToProps)(Dashboard)

const ModeButton = ({ mode, onClick, filterMode }) => {
    return (
        <label className={"btn btn-primary " + (filterMode === mode ? 'active' : '')} style={dateModeStyle} onClick={() => onClick(mode)}>
            <input type="radio" name="options" />{getModeName(mode)}
        </label>
    )
}

const getModeName = (mode) => {
    let name = 'วัน'
    switch (mode) {
        case 'month':
            name = 'เดือน'
            break
        case 'year':
            name = 'ปี'
            break
    }

    return name
}

