import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from 'react-redux'
import _ from 'lodash'
import { withRouter } from "react-router-dom";
import { SortableContainer, SortableElement, arrayMove, SortableHandle } from 'react-sortable-hoc';
import * as hqInfoActions from '../shared/redux/actions/hqInfoActions'
import { bindActionCreators } from 'redux'
import MediaQuery from 'react-responsive';
import firebase from 'firebase'

// const DragHandle = SortableHandle(() => <i className="mdi mdi-source-branch" />); // This can be any component you want

class LeftSideBar extends Component {

  signOut = (e) => {
    e.preventDefault()
    if (window.confirm('Confirm Logout ?')) {
    firebase.auth().signOut();

    }
  }

  render() {
    const { branches, activeLink, setActiveLink, actions, hqInfo, loggedInUser, setEnlarged, currentBranch } = this.props
    return (
      <div className="left side-menu" >
        <div className="slimScrollDiv active"
          style={{ position: "relative", overflow: 'hidden', width: 'auto', height: '100%' }}
        >
          <div className="slimscroll-menu in" id="remove-scroll"
            style={{ overflow: 'hidden', width: 'auto', height: '100%', overflowY: 'scroll', }}
          >

            {/* <!--- Sidemenu --> */}
            <div id="sidebar-menu" className="active"
            >
              {/* <!-- Left Menu Start --> */}
              <ul className="metismenu in" id="side-menu">
                <li className="menu-title">Main</li>
                <li>
                  <Link to="/"
                    onClick={() => {
                      setActiveLink('dashboard')
                      setEnlarged()
                    }}
                    className={`waves-effect ${activeLink === 'dashboard' ? "active" : ''}`}>
                    <i className="dripicons-meter" />
                    <span>Dashboard</span>
                  </Link>
                </li>

                {
                  !loggedInUser.isHq &&
                  <li>
                    <Link
                      onClick={() => {
                        setActiveLink(currentBranch.uuid)
                        setEnlarged()
                      }}
                      to={`/branch/${currentBranch.uuid}`}
                      className={`waves-effect ${activeLink.toString() === currentBranch.uuid ? "active" : ''}`}>
                      <i className="mdi mdi-file-chart" />
                      <span>Reports</span>
                    </Link>
                  </li>
                }

                {/* <li>
                  <Link to="/liveScore"
                    onClick={() => {
                      setActiveLink('liveScore')
                      setEnlarged()
                    }}
                    className={`waves-effect ${activeLink === 'liveScore' ? "active" : ''}`}>
                    <i className="mdi mdi-theater" />
                    <span>Live Score</span>
                  </Link>
                </li> */}

              </ul>

              {
                loggedInUser.isHq &&
                <SortableComponent
                  handleClick={setActiveLink}
                  branches={branches}
                  hqInfo={hqInfo}
                  activeLink={activeLink}
                  actions={actions}
                  setEnlarged={setEnlarged}
                />
              }



              <MediaQuery maxDeviceWidth={500}>
                <ul className="metismenu in" id="side-menu">
                  <li className="menu-title">Settings</li>
                  <li >
                    <a className={`waves-effect`} onClick={this.signOut} >
                      <i className="mdi mdi-logout" />
                      <span >Logout</span>
                    </a>
                  </li>
                </ul>
              </MediaQuery>

            </div>
            {/* <!-- Sidebar --> */}
            <div className="clearfix"></div>
          </div>
          <div className="slimScrollBar"></div>
          <div className="slimScrollRail"></div>
        </div>
        {/* <!-- Sidebar -left --> */}

      </div >
    )
  }
}

class SortableComponent extends Component {
  state = {
    items: [],
    timestamp: 1
  }
  componentWillReceiveProps(nextProps) {
    let { timestamp, hqInfo } = nextProps
    // if (timestamp !== this.state.timestamp) {
    this.setState({ items: hqInfo.branches, timestamp })
    // }
  }

  componentDidMount() {
    let { timestamp, hqInfo } = this.props
    this.setState({ items: hqInfo.branches, timestamp })
  }

  onSortEnd = ({ oldIndex, newIndex }) => {
    let { actions } = this.props
    let newItems = arrayMove(this.state.items, oldIndex, newIndex)
    this.setState({
      items: newItems,
    });
    actions.sortBranch(newItems)
  };
  render() {
    let { handleClick, activeLink, branches, setEnlarged } = this.props

    if (_.isEmpty(branches)) return <div></div>
    return <SortableList
      useDragHandle={true}
      handleClick={handleClick}
      branches={branches}
      onSortEnd={this.onSortEnd}
      activeLink={activeLink}
      pressDelay={2000}
      helperClass={"yellowBG"}
      setEnlarged={setEnlarged}
    />
  }
}

const SortableList = SortableContainer(({ handleClick, activeLink, branches, setEnlarged }) => {

  return (
    <ul className="metismenu in" id="side-menu">
      <li className="menu-title">Branches</li>
      {_.map(branches, (value, index) => {
        return (
          <SortableItem
            key={`item-${index}`}
            index={index}
            value={value}
            handleClick={handleClick}
            activeLink={activeLink}
            branches={branches}
            // distance={2000}
            helperClass={{ visibility: 'visible' }}
            setEnlarged={setEnlarged}
          />
        )
      })}
    </ul>
  );
});

const SortableItem = SortableElement(({ value, handleClick, activeLink, branches, setEnlarged }) =>
  <li
    style={{ visibility: 'visible', zIndex: 999999, opacity: 100 }}
  >
    <Link
      // style={{ visibility: 'visible', backgroundColor: 'transparent' }}
      onClick={() => {
        handleClick(value.uuid)
        setEnlarged()
      }}
      to={`/branch/${value.uuid}`}
      className={`waves-effect ${activeLink.toString() === value.toString() ? "active" : ''}`}
    >
      <i className="mdi mdi-source-branch" />
      {/* <DragHandle /> */}
      <span>{value.branchId} {value.name}</span>

    </Link>
  </li>
);

const mapStateToProps = (state) => {
  return {
    hqInfo: state.HQ_Info,
    branches: state.branches,
    loggedInUser: state.user.loggedIn,
    currentBranch: _.find(state.branches, i => i.customerId === state.user.loggedIn.customerId) || {}

  }
}

function mapDispatchToProps(dispatch) {
  let actions = bindActionCreators({ ...hqInfoActions }, dispatch)
  return {
    actions,
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LeftSideBar))