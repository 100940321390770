import * as firebaseDb from '../../firebase/firesbaseRefs'
import * as type from './type'
// let ls = require('local-storage');

export const fetchShop = (user,cb) => {
    return (dispatch, getState) => {

        // let { user } = getState()
        // let customerId = ls.get('customerId') || "0000"

        firebaseDb.firestoreShopsRef.doc(user.customerId).get().then(function (doc) {
            dispatch({
                type: type.FETCH_SHOP,
                payload: doc.data() || {}
            })
            cb && cb()
        })
            .catch(function (error) {
                alert(error)
            });

    }
}