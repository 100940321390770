import * as type from './type'
import { setInitialLoading } from './loadingActions'
import _ from 'lodash'
import * as firebaseDb from '../../firebase/firesbaseRefs'

export const fetchBranches = (user, cb) => {
    
    return (dispatch, getState) => {
        firebaseDb.branchRef.child(user.hqId || user.customerId)
            .on('value', (snapshot) => {
                let list = snapshot.val();
                
                dispatch({
                    type: type.FETCH_BRANCHES,
                    payload: list ? _.orderBy(list, 'branchId', 'asc') : []
                })
                dispatch(setInitialLoading(false))
                
                cb && cb()

            })
            
    }
}