export const FETCH_LOGGEDIN_USER = "FETCH_LOGGEDIN_USER"
export const FETCH_HQ = "FETCH_HQ"
export const FETCH_BRANCHES = "FETCH_BRANCHES"
export const FETCH_SALES_SUMMARY = "FETCH_SALES_SUMMARY"
export const SET_INITIAL_LOADING = "SET_INITIAL_LOADING"
export const SET_CUSTOM_LOADING = "SET_CUSTOM_LOADING"
export const FETCH_SHOP = "FETCH_SHOP"
export const MENU_FETCH_LIST = "MENU_FETCH_LIST"
export const TOPPING_FETCH_LIST = "TOPPING_FETCH_LIST"






